<template>
    <div class="board-input">
        <div class="board-input__contents">
            <div class="py-12">
                <v-row no-gutters align="center">
                    <v-col cols="12" md="2" class="board-input__row-header"><span>Title</span></v-col>
                    <v-col cols="12" md="10">
                        <v-text-field v-model="board.subject" @input="$emit('input', board)" outlined hide-details />
                    </v-col>
                </v-row>
            </div>
            <v-divider />

            <!-- <div class="py-12">
                <v-row no-gutters align="center">
                    <v-col cols="12" md="2" class="board-input__row-header"><span>Is private</span></v-col>
                    <v-col cols="12" md="10">
                        <v-switch v-model="board.meta.isPrivate" class="ma-0 ml-4 pa-0" inset hide-details />
                    </v-col>
                </v-row>
            </div>
            <v-divider /> -->

            <div class="py-12">
                <v-textarea v-model="board.content" outlined hide-details @input="$emit('input', board)" />
            </div>
        </div>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col cols="auto">
                    <v-btn small exact color="grey-6" class="w-100px" @click="$router.go(-1)">Cancel</v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn small exact color="secondary" class="w-100px" @click="$emit('save')">Confirm</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            board: this.$props.value,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
};
</script>
